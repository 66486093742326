import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAdd } from "react-icons/md";
import { IoMdCloudUpload } from "react-icons/io";
import { FaMinus, FaPlus } from "react-icons/fa";

function HeroSection() {
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalImage, setShowModalImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [originalAppSectionImage, setOriginalAppSectionImage] = useState("");
  const [inspirationSectionImage, setInspirationSectionImage] = useState("");
  const [heroSectionImage, setHeroSectionImage] = useState([]);
  const [originalHeroSectionImage, setOriginalHeroSectionImage] = useState([]);
  const [formData, setFormData] = useState({
    app_section_image: "",
    app_section_play_store: "",
    app_section_app_store: "",
    app_section_heading: "",
    app_section_sub_heading: "",
    hero_heading: "",
    hero_sub_heading: "",
    hero_chat_with_us: "",
    hero_know_more: "",
    inspiration_image: "",
    inspiration_text: "",
    happy_users: "",
    rating: "",
    patients: "",
    service: "",
    // hero_images: [],
  });

  const inputStyle = {
    margin: "10px",
    padding: "5px",
    width: "60%",
    border: "1px solid #d3d3d3",
    borderRadius: "2px",
    background: "var(--green)",
    color: "white",
    fontWeight: "400",
  };

  const submitButtonStyle = {
    margin: "10px",
    fontSize: "16px",
    background: "var(--green)",
    border: "none",
    borderRadius: "5px",
    padding: "5px 10px",
    color: "white",
    width: "100px",
    justifyContent: "center",
    cursor: "pointer",
  };

  const cancelButtonStyle = {
    margin: "10px",
    fontSize: "16px",
    background: "#d3d3d3",
    border: "none",
    borderRadius: "5px",
    width: "100px",
    justifyContent: "center",
    padding: "5px 10px",
    color: "black",
    cursor: "pointer",
  };

  const handleHeroSection = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `https://api.healinroots.com/api/website/home`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const allData = response.data.data.homePageData;
      setAllData(allData);
      setFormData({
        app_section_image: allData?.app_section_image || "",
        app_section_play_store: allData?.app_section_play_store || "",
        app_section_app_store: allData?.app_section_app_store || "",
        app_section_heading: allData?.app_section_heading || "",
        app_section_sub_heading: allData?.app_section_sub_heading || "",
        hero_heading: allData?.hero_heading || "",
        hero_sub_heading: allData?.hero_sub_heading || "",
        hero_chat_with_us: allData?.hero_chat_with_us || "",
        hero_know_more: allData?.hero_know_more || "",
        inspiration_image: allData?.inspiration_image || "",
        inspiration_text: allData?.inspiration_text || "",
        happy_users: allData?.happy_users || "",
        rating: allData?.rating || "",
        patients: allData?.patients || "",
        service: allData?.service || "",
      });
      setHeroSectionImage(allData?.hero_images || "");
      setOriginalHeroSectionImage(allData?.hero_images || []);

      setOriginalAppSectionImage(allData?.app_section_image || "");
      setInspirationSectionImage(allData?.inspiration_image || "");
    } catch (error) {
      console.log("Error fetching Hero Section", error);
    } finally {
      setLoading(false);
    }
  };
  console.log("hero image", heroSectionImage);
  const excludedKeys = ["_id", "createdAt", "updatedAt", "__v"];

  useEffect(() => {
    handleHeroSection();
  }, []);

  const handleAddDataClick = () => {
    setShowModal(true);
  };
  const handleAddImageClick = () => {
    setShowModalImage(true);
  };
  const handleCloseModal = () => {
    setFormData({
      app_section_image: "",
      app_section_play_store: "",
      app_section_app_store: "",
      app_section_heading: "",
      app_section_sub_heading: "",
      hero_heading: "",
      hero_sub_heading: "",
      hero_chat_with_us: "",
      hero_know_more: "",
      inspiration_image: "",
      inspiration_text: "",
      happy_users: "",
      rating: "",
      patients: "",
      service: "",
    });
    setSelectedFile("");
    setShowModal(false);
    setShowModalImage(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAppSectionImageChange = (e) => {
    setFormData({ ...formData, app_section_image: e.target.files[0] });
  };

  const handleInspirationImage = (e) => {
    setFormData({ ...formData, inspiration_image: e.target.files[0] });
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];

    if (file && file.size > 1 * 1024 * 1024) {
      toast.error("Image size should not exceed 1MB");
      return;
    }

    const newImages = [...heroSectionImage];
    newImages[index] = file;
    setHeroSectionImage(newImages);
  };

  const addHeroImageField = () => {
    setHeroSectionImage([...heroSectionImage, null]);
  };

  const removeHeroImageField = (index) => {
    const newImages = heroSectionImage.filter((_, idx) => idx !== index);
    setHeroSectionImage(newImages);
  };
  const handleFormImage = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");

    try {
      const existingImageUrls = heroSectionImage.filter(
        (image) => typeof image === "string"
      ); // URLs of previously uploaded images
      const newFiles = heroSectionImage.filter(
        (image) => typeof image !== "string"
      ); // New images to upload

      const uploadImage = async (imageFile) => {
        const imageUploadFormData = new FormData();
        imageUploadFormData.append("file", imageFile);

        const imageUploadResponse = await axios.post(
          "https://api.healinroots.com/api/website/file/upload",
          imageUploadFormData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (imageUploadResponse.data.uploaded) {
          return imageUploadResponse.data.url;
        } else {
          toast.error(
            "Image upload failed: " + imageUploadResponse.data.message
          );
          throw new Error("Image upload failed");
        }
      };
      const newImageUrls = await Promise.all(
        newFiles.map(async (image) => await uploadImage(image))
      );
      const finalHeroImageUrls = [...existingImageUrls, ...newImageUrls];
      const dataToSend = {
        hero_images: finalHeroImageUrls,
      };

      const response = await axios.post(
        "https://api.healinroots.com/api/website/home/hero-section",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast.success("Form submitted successfully");
      handleCloseModal();
      handleHeroSection();
      console.log(response.data);
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      toast.error(error.response?.data?.message || "Failed submitting form");
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");
    try {
      let appSectionImageUrl = formData.app_section_image;
      let inspirationImage = formData.inspiration_image;

      // Upload only if app_section_image has changed
      if (formData.app_section_image !== originalAppSectionImage) {
        const uploadImage = async (imageFile) => {
          const imageUploadFormData = new FormData();
          imageUploadFormData.append("file", imageFile);

          const imageUploadResponse = await axios.post(
            "https://api.healinroots.com/api/website/file/upload",
            imageUploadFormData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (imageUploadResponse.data.uploaded) {
            return imageUploadResponse.data.url; // Return the uploaded image URL
          } else {
            throw new Error("Image upload failed");
          }
        };

        // Step 1: Upload app_section_image
        appSectionImageUrl = await uploadImage(formData.app_section_image);
      }

      if (formData.inspiration_image !== inspirationSectionImage) {
        const uploadImage = async (imageFile) => {
          const imageUploadFormData = new FormData();
          imageUploadFormData.append("file", imageFile);

          const imageUploadResponse = await axios.post(
            "https://api.healinroots.com/api/website/file/upload",
            imageUploadFormData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (imageUploadResponse.data.uploaded) {
            return imageUploadResponse.data.url; // Return the uploaded image URL
          } else {
            throw new Error("Image upload failed");
          }
        };

        // Step 1: Upload app_section_image
        inspirationImage = await uploadImage(formData.inspiration_image);
      }


      // Step 3: Prepare the data to send with the image URL
      const dataToSend = {
        app_section_image: appSectionImageUrl,
        app_section_play_store: formData.app_section_play_store,
        app_section_app_store: formData.app_section_app_store,
        app_section_heading: formData.app_section_heading,
        app_section_sub_heading: formData.app_section_sub_heading,
        hero_heading: formData.hero_heading,
        hero_sub_heading: formData.hero_sub_heading,
        hero_chat_with_us: formData.hero_chat_with_us,
        hero_know_more: formData.hero_know_more,
        inspiration_image: inspirationImage,
        inspiration_text: formData.inspiration_text,
        happy_users: formData.happy_users,
        rating: formData.rating,
        patients: formData.patients,
        service: formData.service,
      };

      // Step 4: Submit the form data to the main API
      const response = await axios.post(
        "https://api.healinroots.com/api/website/home",
        dataToSend,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      //   alert('Form submitted successfully!');
      toast.success("Form submitted successfully");
      handleCloseModal();
      handleHeroSection();
      console.log(response.data);
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      toast.success(error.response.data.message || "Failed submitting form");
      //   alert('Error submitting form');
    }
  };

  return (
    <div style={{ background: "#eaeaea", width: "100%", height: "100%" }}>
      <div
        style={{
          width: "80%",
          marginInline: "auto",
          paddingTop: "50px",
          background: "#eaeaea",
        }}
      >
        <ToastContainer />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h1
            style={{
              display: "flex",
              fontSize: "calc(1.375rem + 1.5vw)",
              fontWeight: "600",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            Hero Section{" "}
          </h1>
          <div
            style={{ display: "flex", marginLeft: "auto", marginRight: "10px" }}
          >
            <button
              style={{
                background: "var(--green)",
                fontSize: "18px",
                border: "none",
                color: "white",
                padding: "5px 20px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={handleAddDataClick}
            >
              Edit Hero Section
            </button>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            overflow: "scroll",
            maxHeight: "70vh",
            paddingInline: "20px",
            backgroundColor: "#F8F9FA",
          }}
        >
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead style={{ position: "sticky", top: "-1px" }}>
              <tr
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  paddingTop: "15px",
                }}
              >
                <th
                  style={{
                    borderBottom: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                    paddingBlock: "10px",
                    backgroundColor: "#F8F9FA",
                    fontFamily: "Montserrat",
                  }}
                >
                  Key
                </th>
                <th
                  style={{
                    borderBottom: "1px solid #dddddd",
                    textAlign: "left",
                    padding: "8px",
                    paddingBlock: "10px",
                    backgroundColor: "#F8F9FA",
                    fontFamily: "Montserrat",
                  }}
                >
                  Value
                </th>
              </tr>
            </thead>
            <tbody style={{ overflowY: "auto" }}>
              {loading ? (
                <tr>
                  <td
                    colSpan="2"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    Loading...
                  </td>
                </tr>
              ) : (
                Object.entries(allData)
                  .filter(([key]) => !excludedKeys.includes(key))
                  .map(([key, value], index) => (
                    <tr key={index} style={{ background: "#F8F9FA" }}>
                      <td
                        style={{
                          borderBottom: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          paddingBlock: "8px",
                          fontFamily: "Montserrat",
                          textTransform: "capitalize",
                          fontWeight: "bold",
                        }}
                      >
                        {key.split("_").join(" ")}
                      </td>
                      <td
                        style={{
                          borderBottom: "1px solid #dddddd",
                          textAlign: "left",
                          padding: "8px",
                          paddingBlock: "8px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {Array.isArray(value) ? (
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {" "}
                            {/* Added flex wrap here */}
                            {value.map((v, i) => (
                              <img
                                key={i}
                                src={v}
                                alt="Image"
                                style={{
                                  width: "150px",
                                  height: "100px",
                                  marginRight: "10px",
                                  marginBottom: "10px",
                                }} // Added marginBottom for space between rows
                              />
                            ))}
                          </div>
                        ) : key.includes("image") ? (
                          <img
                            src={value}
                            alt="Image"
                            style={{ width: "150px", height: "100px" }}
                          />
                        ) : (
                          value
                        )}
                        {key === "hero_images" && (
                          <div style={{ display: "flex" }}>
                            <div
                              style={{
                                display: "flex",
                                marginLeft: "auto",
                                marginRight: "10px",
                              }}
                            >
                              <button
                                style={{
                                  background: "var(--green)",
                                  fontSize: "14px",
                                  border: "none",
                                  color: "white",
                                  padding: "5px 10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={handleAddImageClick}
                              >
                                Edit Hero Section Image
                              </button>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        {/* Add new data */}
        {showModal && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 99,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                height: "90vh",
                overflowY: "auto",
                borderRadius: "5px",
                width: "450px",
              }}
            >
              <form onSubmit={handleForm}>
                {/* App Section */}
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  App Section
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Heading
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="app_section_heading"
                    value={formData.app_section_heading}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Sub Heading
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="app_section_sub_heading"
                    value={formData.app_section_sub_heading}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Play Store Link
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="app_section_play_store"
                    value={formData.app_section_play_store}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    App Store Link
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="app_section_app_store"
                    value={formData.app_section_app_store}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    App Image
                  </label>
                  <input
                    type="file"
                    style={inputStyle}
                    onChange={handleAppSectionImageChange}
                    accept="image/*"
                  />
                </div>

                {/* Hero Section */}
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Hero Section
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Hero Heading
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="hero_heading"
                    value={formData.hero_heading}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Hero Sub Heading
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="hero_sub_heading"
                    value={formData.hero_sub_heading}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Chat With Us
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="hero_chat_with_us"
                    value={formData.hero_chat_with_us}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Know More
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="hero_know_more"
                    value={formData.hero_know_more}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Inspiration Section
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    inspiration Image
                  </label>
                  <input
                    type="file"
                    style={inputStyle}
                    onChange={handleInspirationImage}
                    accept="image/*"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Inspiration Text
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="inspiration_text"
                    value={formData.inspiration_text}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Stats Banner
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Happy Users
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="happy_users"
                    value={formData.happy_users}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Rating
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="rating"
                    value={formData.rating}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Patients
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="patients"
                    value={formData.patients}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <label style={{ width: "40%", padding: "5px" }}>
                    Service
                  </label>
                  <input
                    type="text"
                    style={inputStyle}
                    name="service"
                    value={formData.service}
                    onChange={handleChange}
                    required
                  />
                </div>


                {/* Submit and Cancel */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button type="submit" style={submitButtonStyle}>
                    Submit
                  </button>
                  <button
                    type="button"
                    style={cancelButtonStyle}
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {showModalImage && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 99,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                maxHeight: "80vh",
                overflowY: "auto",
                borderRadius: "5px",
                width: "450px",
              }}
            >
              <form onSubmit={handleFormImage}>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: 600,
                    marginBottom: "10px",
                  }}
                >
                  Hero Section Image
                </div>

                <div>
                  {heroSectionImage?.map((_, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <label style={{ width: "40%", padding: "5px" }}>
                        Hero Image {index + 1}
                      </label>
                      <div style={{ width: "50%" }}>
                        <input
                          type="file"
                          style={{
                            ...inputStyle,
                            width: "100%",
                            margin: "0px",
                          }}
                          //   style={{ width: "100%", margin: "0px" }}
                          onChange={(e) => handleImageChange(e, index)}
                          accept="image/*"
                        />
                      </div>
                      <div style={{ width: "10%" }}>
                        <button
                          type="button"
                          onClick={() => removeHeroImageField(index)}
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "red",
                            color: "white",
                            padding: "5px",
                            borderRadius: "50px",
                          }}
                        >
                          <FaMinus />
                        </button>
                      </div>
                    </div>
                  ))}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "10px",
                    }}
                  >
                    <button
                      type="button"
                      onClick={addHeroImageField}
                      style={{
                        cursor: "pointer",
                        fontSize: "16px",
                        padding: "5px 10px",
                        backgroundColor: "#28a745",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        marginLeft: "10px",
                      }}
                    >
                      <FaPlus />
                    </button>
                  </div>
                </div>

                {/* Submit and Cancel */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button type="submit" style={submitButtonStyle}>
                    Submit
                  </button>
                  <button
                    type="button"
                    style={cancelButtonStyle}
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeroSection;
