import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAdd } from "react-icons/md";
import { IoMdCloudUpload } from "react-icons/io";

function Services() {
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");
    const [formData, setFormData] = useState({
        id:"",
        sequence: "",
        body: ""
      });

      const handleServices = async() => {
        try {
            const authToken = localStorage.getItem("jwt");
          const response = await axios.get(
            `https://api.healinroots.com/api/service`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
            console.log("Services Fetched successfully", response.data.data)
            setAllData(response.data.data);
        } catch (error) {
            console.log("Error fetching services", error);
        } finally {
          setLoading(false);
      }
      }

      useEffect(() => {
        handleServices();
      },[]);

      const handleAddDataClick = () => {
        setShowModal(true);
      };
      const handleCloseModal = () => {
        setFormData({
          id:"",
          sequence: "",
        body: ""
        });
        setSelectedFile("");
        setShowModal(false);
        setEditModal(false);
        setDeleteModal(false);
        // setSelected(null);
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const handleForm = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem("jwt");
      
        if (!authToken) {
          toast.error("No authentication token found. Please log in.");
          return;
        }
      
        try {
          // Step 1: Upload the image if selected
          let imageUrl = '';
          if (selectedFile) {
            const imageFormData = new FormData();
            imageFormData.append('file', selectedFile);
      
            const imageResponse = await axios.post(
              'https://api.healinroots.com/api/website/file/upload',
              imageFormData,
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                  'Content-Type': 'multipart/form-data',
                },
              }
            );
      
            if (imageResponse.data.uploaded) {
              imageUrl = imageResponse.data.url;
            } else {
              toast.error("Image upload failed");
              return;
            }
          }
      
          // Step 2: Create the service
          const serviceData = {
            image: imageUrl,
            text: formData.body,
            sequence: formData.sequence,
          };
      
          await axios.post(
            'https://api.healinroots.com/api/service',
            serviceData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
              },
            }
          );
      
          toast.success("Service added successfully");
          handleServices();
          handleCloseModal();
        } catch (error) {
          console.error("Failed adding Service", error);
          toast.error(error.response?.data?.message || "Failed adding Service");
        }
      };
      
    
      
      

  // edit entry
  const [oldSequene, setOldSequence] = useState("");
  const handleEditClick = async (data) => {
    setOldSequence(data.sequence || "")
        setFormData({
      id: data._id || "",
      sequence: data.sequence || "",
      body: data.text || "",
      image: data.image || "" 
    });
  
    setEditModal(true);
  };
  
  

  const handleUpdate = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");
  
    if (!authToken) {
      toast.error("No authentication token found. Please log in.");
      return;
    }
  
    if (!formData.id) {
      toast.error("Error updating data");
      return;
    }
  
    let imageUrl = formData.image; // Keep the existing image URL initially
  
    // Create a new FormData object to send
    const formDataToSend = new FormData();
    
    // Only append 'sequence' if it has changed
    if (formData.sequence !== oldSequene) {
      formDataToSend.append('sequence', formData.sequence);
    }
  
    formDataToSend.append('body', formData.body); // Always include 'body'
    
    // Append the new image if it's changed
    if (selectedFile) {
      const imageFormData = new FormData();
      imageFormData.append('file', selectedFile);
  
      try {
        const imageResponse = await axios.post(
          'https://api.healinroots.com/api/website/file/upload',
          imageFormData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
  
        if (imageResponse.data.uploaded) {
          imageUrl = imageResponse.data.url; // Update the image URL with the new one
        } else {
          toast.error("Image upload failed");
          return;
        }
      } catch (error) {
        console.error("Failed uploading image", error);
        toast.error("Failed uploading image");
        return;
      }
    }
  
    // Now append the image URL to the form data (if it's a new image)
    formDataToSend.append('image', imageUrl);
  
    // Try sending the updated data
    try {
      const response = await axios.put(
        `https://api.healinroots.com/api/service/${formData.id}`,
        formDataToSend, // Send the form data with all fields
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'multipart/form-data', // Ensure the content type is multipart/form-data
          },
        }
      );
  
      toast.success("Service updated successfully");
      handleServices();
      handleCloseModal();
    } catch (error) {
      console.error("Failed updating Service", error);
      const errorMessage = error.response?.data?.message || "Failed updating Service";
      toast.error(errorMessage);
    }
  };
  
  
  
  
    //   Delete API
    const handleDeleteClick = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
      };

      const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem("jwt");

          const response = await axios.delete(
            `https://api.healinroots.com/api/service/${deleteId}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
    
          toast.success("Service Deleted Successfully");
          handleServices();
          setDeleteId("");
          handleCloseModal();
        } catch (error) {
            console.log("Failed deleting Service", error);
          toast.error("Failed Deleting Service")
        }
      };

      const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          if (file.type.startsWith("image/")) {
            setSelectedFile(file);
          } else {
            toast.error("Please select a valid image file.");
            setSelectedFile(null);
          }
        } else {
          setSelectedFile(null);
        }
      };
      
      console.log("image", selectedFile)
  return (
  <div style={{background: "#eaeaea", width:"100%", height:"100%"  }}>
  <div style={{width:"80%", marginInline:"auto", paddingTop:"50px",background: "#eaeaea"  }}>
  <ToastContainer/>
   <div
        style={{
          display: "flex",
          flexDirection:"column",
          width: "100%",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <h1
          style={{
            display: "flex",
            fontSize: "calc(1.375rem + 1.5vw)",
            fontWeight: "600",
            justifyContent: "start",
            alignItems: "center",

          }}
        >
          Services{" "}
        </h1>
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "10px" }}>
          <button style={{
    background: "var(--green)",
    fontSize: "18px",
            border: "none",
            color: "white",
            padding: "5px 20px",
            display:"flex",
            alignItems:"center"
          }}
            onClick={handleAddDataClick}
            >Add Services</button>
        </div>
      </div>
    <div style={{ width: "100%", overflow: "scroll", maxHeight: "70vh", paddingInline:"20px", backgroundColor: "#F8F9FA" }}>
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead style={{ position: "sticky", top: "-1px" }}>
        <tr style={{fontSize:"18px", fontWeight:"600", paddingTop:"15px"}}>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Sequence</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Text</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Image</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Action</th>
        </tr>
      </thead>
      <tbody style={{ overflowY: "auto" }}>
      {loading ? (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: "center", padding: "20px" }}>Loading...</td>
                                </tr>
                            ) : allData.length === 0 ? (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: "center", padding: "20px" }}>No data available.</td>
                                </tr>
                            ) : (
                              allData?.map((data, index) => (
          <tr key={index} style={{background:"#F8F9FA"}}>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}>{data?.sequence}</td>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}>{data?.text}</td>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}><img src={data?.image} alt="Image" style={{width:"150px", height:"100px"}}/></td>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}><div style={{ display: "flex", flexDirection: "row" }}>
              <FaRegEdit size={20} color={"var(--green)"} style={{ marginInline: "5px", display: "flex" }}  onClick={() => handleEditClick(data)}/>
              <RiDeleteBin5Line size={20} color={"red"} style={{ marginLeft: "5px", display: "flex" }} onClick={() => handleDeleteClick(data?._id)}/></div>
            </td>
          </tr>
 ))
)}
      </tbody>
    </table>
  </div>  
        {/* Add new data */}
        {showModal && ( 
       <div
       style={{
         position: "fixed",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         backgroundColor: "rgba(0, 0, 0, 0.5)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 99,
       }}
     >
       <div
         style={{
           backgroundColor: "white",
           padding: "20px",
           borderRadius: "5px",
           width: "450px",
         }}
       >
         <form onSubmit={(e) => { handleForm(e) }}>
           <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Add Service</div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Sequence</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter sequence"
               value={formData.sequence}
               name="sequence"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Body</label>
             <textarea
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter body"
               value={formData.body}
               name="body"
               onChange={handleChange}
               required
             />
           </div>
     
           <div className="flex flex-col" style={{ height: '200px' }}>
              <label>Image</label>
              <div>
                {selectedFile ? (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Front"
                      style={{
                        width: '100px',
                        height: '100px',
                        marginTop: '5px',
                        borderRadius: '10px',
                        marginInline: 'auto',
                      }}
                    />
                    <div className="flex justify-center mt-2">
                      <button
                        type="button"
                        style={{
                          background: 'var(--green)',
                          color: 'white',
                          border: 'none',
                          borderRadius: '5px',
                          padding: '5px 10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setSelectedFile(null)}
                      >
                        Change Image
                        <input
                          type="file"
                          hidden
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      border: '1px dashed black',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                      width: '100%',
                      height: '150px',
                    }}
                  >
                    <input
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                    />
                    <div className="justify-center flex">
                      <IoMdCloudUpload size={50} />
                    </div>
                    <div className="font-semibold flex justify-center">
                      Browse File to upload
                    </div>
                  </div>
                )}
              </div>
            </div>
     
           <div style={{ display: "flex", justifyContent: "center" }}>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                  background: "var(--green)",
                 border: "none",
                 borderRadius: "5px",
                 padding: "5px 10px",
                 color: "white",
                 width:"100px",
                 justifyContent:"center",
                 cursor: "pointer"
               }}
               type="submit"
             >
               Add
             </button>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                 background: "#d3d3d3",
                 border: "none",
                 borderRadius: "5px",
                 width:"100px",
                 justifyContent:"center",
                 padding: "5px 10px",
                 color: "black",
                 cursor: "pointer"
               }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
         </form>
       </div>
     </div>
     
      )}
        {/* Update data */}
        {editModal && ( 
       <div
       style={{
         position: "fixed",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         backgroundColor: "rgba(0, 0, 0, 0.5)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 99,
       }}
     >
       <div
         style={{
           backgroundColor: "white",
           padding: "20px",
           borderRadius: "5px",
           width: "450px",
         }}
       >
         <form onSubmit={(e) => { handleUpdate(e) }}>
           <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Edit Service</div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Sequence</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter sequence"
               value={formData.sequence}
               name="sequence"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Body</label>
             <textarea
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter body"
               value={formData.body}
               name="body"
               onChange={handleChange}
               required
             />
           </div>
     
           <div className="flex flex-col" style={{ height: '200px' }}>
              <label>Image</label>
              <div>
                {selectedFile ? (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Front"
                      style={{
                        width: '100px',
                        height: '100px',
                        marginTop: '5px',
                        borderRadius: '10px',
                        marginInline: 'auto',
                      }}
                    />
                    <div className="flex justify-center mt-2">
                      <button
                        type="button"
                        style={{
                          background: 'var(--green)',
                          color: 'white',
                          border: 'none',
                          borderRadius: '5px',
                          padding: '5px 10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setSelectedFile(null)}
                      >
                        Change Image
                        <input
                          type="file"
                          hidden
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      border: '1px dashed black',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                      width: '100%',
                      height: '150px',
                    }}
                  >
                    <input
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                    />
                    <div className="justify-center flex">
                      <IoMdCloudUpload size={50} />
                    </div>
                    <div className="font-semibold flex justify-center">
                      Browse File to upload
                    </div>
                  </div>
                )}
              </div>
            </div>
         
     
           <div style={{ display: "flex", justifyContent: "center" }}>
             <button
               style={{
                display: "flex",
                margin: "10px",
                fontSize: "16px",
                background: "var(--green)",
                border: "none",
                borderRadius: "5px",
                padding: "5px 10px",
                color: "white",
                width:"100px",
                justifyContent:"center",
                cursor: "pointer"
              }}
               type="submit"
             >
               Update
             </button>
             <button
               style={{
                display: "flex",
                margin: "10px",
                fontSize: "16px",
                background: "#d3d3d3",
                border: "none",
                borderRadius: "5px",
                width:"100px",
                justifyContent:"center",
                padding: "5px 10px",
                color: "black",
                cursor: "pointer"
              }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
         </form>
       </div>
     </div>
     
      )}

        {/* Delete Entry */}
        {deleteModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "450px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Are you sure you want to delete?</div>

            <div style={{ display: "flex", justifyContent: "center" }}>
            <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "var(--green)", width:"100px",justifyContent:"center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "white", cursor: "pointer" }} onClick={handleDelete}>Delete</button>
            <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "#d3d3d3", width:"100px",justifyContent:"center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "black", cursor: "pointer" }} onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
  </div>
  </div>
  )
}

export default Services