import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAdd } from "react-icons/md";
import { IoMdCloudUpload } from "react-icons/io";

function DiseasesWeCure() {
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [selectedFile, setSelectedFile] = useState("");
    const [formData, setFormData] = useState({
        id:"",
        diseasesOf: "",
        diseaseList: []
      });

      const handleDiseasesWeCure = async() => {
        try {
            const authToken = localStorage.getItem("jwt");
          const response = await axios.get(
            `https://api.healinroots.com/api/diseasewecure/all`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
            console.log("Diseases We Cure Fetched successfully", response.data.data)
            setAllData(response.data.data);
        } catch (error) {
            console.log("Error fetching Diseases We Cure", error);
        } finally {
          setLoading(false);
      }
      }

      useEffect(() => {
        handleDiseasesWeCure();
      },[]);

      const handleAddDataClick = () => {
        setShowModal(true);
      };
      const handleCloseModal = () => {
        setFormData({
          id:"",
        title: "",
        body: ""
        });
        setSelectedFile("");
        setShowModal(false);
        setEditModal(false);
        setDeleteModal(false);
        // setSelected(null);
      };
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const handleForm = async (e) => {
        e.preventDefault();
        const authToken = localStorage.getItem("jwt");
      
        if (!authToken) {
          toast.error("No authentication token found. Please log in.");
          return;
        }
        const diseaseListArray = formData.diseaseList.split(',').map(item => item.trim());

        // Create a new FormData object
        const formDataToSend = new FormData();
        formDataToSend.append('diseasesOf', formData.diseasesOf);
        formDataToSend.append('diseaseList', JSON.stringify(diseaseListArray));
      
        // Append the file
        if (selectedFile) {
          formDataToSend.append('file', selectedFile);
        }
      
        try {
          const response = await axios.post(
            'https://api.healinroots.com/api/diseasewecure',
            formDataToSend,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                // 'Content-Type': 'multipart/form-data' // Note: Axios sets this automatically when using FormData
              },
            }
          );
      
          toast.success("Diseases We Cure added successfully");
          handleDiseasesWeCure();
          handleCloseModal();
        } catch (error) {
          console.log("Failed adding Diseases We Cure", error);
          toast.error("Failed adding Diseases We Cure");
        }
      };
      
      

  // edit entry
  const handleEditClick = async (data) => {
    setFormData({
      id: data._id || "",
    diseasesOf: data.diseasesOf || "",
    diseaseList: Array.isArray(data.diseaseList) ? data.diseaseList : [], // Ensure it's an array
    });
  
    // Fetch the image from the URL and convert it into a File object
    if (data.mediaLink) {
      try {
        const response = await fetch(data.mediaLink);
        const blob = await response.blob();
        const file = new File([blob], "image.jpg", { type: blob.type });
        setSelectedFile(file);
      } catch (error) {
        console.error("Failed to fetch the image", error);
        setSelectedFile(null);
      }
    } else {
      setSelectedFile(null);
    }
  
    setEditModal(true);
  };
  

  const handleUpdate = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");
    
    if (!formData.id) {
      toast.error("Error updating data");
      return;
    }
   // Create a new FormData object
   const formDataToSend = new FormData();
  formDataToSend.append('diseasesOf', formData.diseasesOf);
  formDataToSend.append('diseaseList', JSON.stringify(formData.diseaseList)); // Convert array to JSON string

  if (selectedFile) {
    formDataToSend.append('file', selectedFile);
  }

    try {
      const response = await axios.put(
        `https://api.healinroots.com/api/diseasewecure/${formData.id}`,
          formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
                    },
        }
      );
  
      toast.success("Diseases We Cure updated successfully");
      handleDiseasesWeCure();
      handleCloseModal();
    } catch (error) {
        console.log("Failed updating Diseases We Cure", error)
        toast.error("Failed updating Diseases We Cure");
      } 
    
  };
    //   Delete API
    const handleDeleteClick = (id) => {
        setDeleteModal(true);
        setDeleteId(id);
      };

      const handleDelete = async () => {
        try {
            const authToken = localStorage.getItem("jwt");

          const response = await axios.delete(
            `https://api.healinroots.com/api/diseasewecure/${deleteId}`,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
    
          toast.success(" Diseases We Cure Deleted Successfully");
          handleDiseasesWeCure();
          setDeleteId("");
          handleCloseModal();
        } catch (error) {
            console.log("Failed deleting Diseases We Cure", error);
          toast.error("Failed Deleting Diseases We Cure")
        }
      };

      const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
          setSelectedFile(file);
        } else {
          setSelectedFile(null);
        }
      };
      console.log("image", selectedFile)
  return (
  <div style={{background: "#eaeaea", width:"100%", height:"100%"  }}>
  <div style={{width:"80%", marginInline:"auto", paddingTop:"50px",background: "#eaeaea"  }}>
  <ToastContainer/>
   <div
        style={{
          display: "flex",
          flexDirection:"column",
          width: "100%",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <h1
          style={{
            display: "flex",
            fontSize: "calc(1.375rem + 1.5vw)",
            fontWeight: "600",
            justifyContent: "start",
            alignItems: "center",

          }}
        >
          Diseases We Cure{" "}
        </h1>
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "10px" }}>
          <button style={{
    background: "var(--green)",
    fontSize: "18px",
            border: "none",
            color: "white",
            padding: "5px 20px",
            display:"flex",
            alignItems:"center"
          }}
            onClick={handleAddDataClick}
            >Add Diseases We Cure</button>
        </div>
      </div>
    <div style={{ width: "100%", overflow: "scroll", maxHeight: "70vh", paddingInline:"20px", backgroundColor: "#F8F9FA" }}>
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <thead style={{ position: "sticky", top: "-1px" }}>
        <tr style={{fontSize:"18px", fontWeight:"600", paddingTop:"15px"}}>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>ID</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Diseases</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Disease List</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Image</th>
          <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px",paddingBlock:"10px", backgroundColor: "#F8F9FA", fontFamily:"Montserrat" }}>Action</th>
        </tr>
      </thead>
      <tbody style={{ overflowY: "auto" }}>
      {loading ? (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: "center", padding: "20px" }}>Loading...</td>
                                </tr>
                            ) : allData.length === 0 ? (
                                <tr>
                                    <td colSpan="5" style={{ textAlign: "center", padding: "20px" }}>No data available.</td>
                                </tr>
                            ) : (
                              allData?.map((data, index) => (
          <tr key={index} style={{background:"#F8F9FA"}}>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}>{data?._id}</td>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}>{data?.diseasesOf}</td>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}>{data?.diseaseList.join(", ")}</td>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}><img src={data?.mediaLink} alt="Image" style={{width:"150px", height:"100px"}}/></td>
            <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", paddingBlock:"8px", fontFamily:"Montserrat" }}><div style={{ display: "flex", flexDirection: "row" }}>
              <FaRegEdit size={20} color={"var(--green)"} style={{ marginInline: "5px", display: "flex" }}  onClick={() => handleEditClick(data)}/>
              <RiDeleteBin5Line size={20} color={"red"} style={{ marginLeft: "5px", display: "flex" }} onClick={() => handleDeleteClick(data?._id)}/></div>
            </td>
          </tr>
 ))
)}
      </tbody>
    </table>
  </div>  
        {/* Add new data */}
        {showModal && ( 
       <div
       style={{
         position: "fixed",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         backgroundColor: "rgba(0, 0, 0, 0.5)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 99,
       }}
     >
       <div
         style={{
           backgroundColor: "white",
           padding: "20px",
           borderRadius: "5px",
           width: "450px",
         }}
       >
         <form onSubmit={(e) => { handleForm(e) }}>
           <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Add Diseases We Cure</div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Diseases</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter diseases"
               value={formData.diseasesOf}
               name="diseasesOf"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Disease List</label>
             <textarea
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter disease list (comma separated)"
               value={formData.diseaseList}
               name="diseaseList"
               onChange={handleChange}
               required
             />
           </div>
     
           <div className="flex flex-col" style={{ height: '200px' }}>
              <label>Image</label>
              <div>
                {selectedFile ? (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Front"
                      style={{
                        width: '100px',
                        height: '100px',
                        marginTop: '5px',
                        borderRadius: '10px',
                        marginInline: 'auto',
                      }}
                    />
                    <div className="flex justify-center mt-2">
                      <button
                        type="button"
                        style={{
                          background: 'var(--green)',
                          color: 'white',
                          border: 'none',
                          borderRadius: '5px',
                          padding: '5px 10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setSelectedFile(null)}
                      >
                        Change Image
                        <input
                          type="file"
                          hidden
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      border: '1px dashed black',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                      width: '100%',
                      height: '150px',
                    }}
                  >
                    <input
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                    />
                    <div className="justify-center flex">
                      <IoMdCloudUpload size={50} />
                    </div>
                    <div className="font-semibold flex justify-center">
                      Browse File to upload
                    </div>
                  </div>
                )}
              </div>
            </div>
     
           <div style={{ display: "flex", justifyContent: "center" }}>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                  background: "var(--green)",
                 border: "none",
                 borderRadius: "5px",
                 padding: "5px 10px",
                 color: "white",
                 width:"100px",
                 justifyContent:"center",
                 cursor: "pointer"
               }}
               type="submit"
             >
               Add
             </button>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                 background: "#d3d3d3",
                 border: "none",
                 borderRadius: "5px",
                 width:"100px",
                 justifyContent:"center",
                 padding: "5px 10px",
                 color: "black",
                 cursor: "pointer"
               }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
         </form>
       </div>
     </div>
     
      )}
        {/* Update data */}
        {editModal && ( 
       <div
       style={{
         position: "fixed",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         backgroundColor: "rgba(0, 0, 0, 0.5)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: 99,
       }}
     >
       <div
         style={{
           backgroundColor: "white",
           padding: "20px",
           borderRadius: "5px",
           width: "450px",
         }}
       >
         <form onSubmit={(e) => { handleUpdate(e) }}>
           <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Edit Diseases We Cure</div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
             <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Diseases</label>
             <input
               type="text"
               style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400"               }}
               placeholder="Enter diseases"
               value={formData.diseasesOf}
               name="diseasesOf"
               onChange={handleChange}
               required
             />
           </div>
     
           <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
    <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Disease List</label>
    <textarea
      type="text"
      style={{ margin: "10px", padding: "5px", width: "60%", border:"1px solid #d3d3d3", borderRadius:"2px",background: "var(--green)", color:"white", fontWeight:"400" }}
      placeholder="Enter disease list (comma separated)"
      value={formData.diseaseList ? formData.diseaseList.join(", ") : ""} // Default to empty string if undefined
      name="diseaseList"
      onChange={(e) => setFormData({ ...formData, diseaseList: e.target.value.split(", ") })}
      required
    />
  </div>
     
           <div className="flex flex-col" style={{ height: '200px' }}>
              <label>Image</label>
              <div>
                {selectedFile ? (
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Front"
                      style={{
                        width: '100px',
                        height: '100px',
                        marginTop: '5px',
                        borderRadius: '10px',
                        marginInline: 'auto',
                      }}
                    />
                    <div className="flex justify-center mt-2">
                      <button
                        type="button"
                        style={{
                          background: 'var(--green)',
                          color: 'white',
                          border: 'none',
                          borderRadius: '5px',
                          padding: '5px 10px',
                          cursor: 'pointer',
                        }}
                        onClick={() => setSelectedFile(null)}
                      >
                        Change Image
                        <input
                          type="file"
                          hidden
                          onChange={handleImageChange}
                          accept="image/*"
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      border: '1px dashed black',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                      cursor: 'pointer',
                      width: '100%',
                      height: '150px',
                    }}
                  >
                    <input
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                    />
                    <div className="justify-center flex">
                      <IoMdCloudUpload size={50} />
                    </div>
                    <div className="font-semibold flex justify-center">
                      Browse File to upload
                    </div>
                  </div>
                )}
              </div>
            </div>
         
     
           <div style={{ display: "flex", justifyContent: "center" }}>
             <button
               style={{
                display: "flex",
                margin: "10px",
                fontSize: "16px",
                background: "var(--green)",
                border: "none",
                borderRadius: "5px",
                padding: "5px 10px",
                color: "white",
                width:"100px",
                justifyContent:"center",
                cursor: "pointer"
              }}
               type="submit"
             >
               Update
             </button>
             <button
               style={{
                display: "flex",
                margin: "10px",
                fontSize: "16px",
                background: "#d3d3d3",
                border: "none",
                borderRadius: "5px",
                width:"100px",
                justifyContent:"center",
                padding: "5px 10px",
                color: "black",
                cursor: "pointer"
              }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
         </form>
       </div>
     </div>
     
      )}

        {/* Delete Entry */}
        {deleteModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "450px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Are you sure you want to delete?</div>

            <div style={{ display: "flex", justifyContent: "center" }}>
            <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "var(--green)", width:"100px",justifyContent:"center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "white", cursor: "pointer" }} onClick={handleDelete}>Delete</button>
            <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "#d3d3d3", width:"100px",justifyContent:"center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "black", cursor: "pointer" }} onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}
  </div>
  </div>
  )
}

export default DiseasesWeCure