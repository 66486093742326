const endUrls = {
    baseUrl: "https://api.healinroots.com/api"  ,
   // baseUrl: "http://127.0.0.1:8000/api",
    sendOtp: "/send-otp",
    verifyOtp: "/verify-otp",
    enterDetail: "/add-detail",
    questionnaire: "/view-sequence",
    viewMembership: "/view-membership",
    userById: "/view-user",
    viewSlots:"/view-slot",
    viewDietAttempt:"/view-diet-attempt",
    viewAttempt:"/view-attempt",
    addAttempt:"/add-attempt",
    buyMembership:"/buy-membership",
    addAppointment:"/add-appointment",
    viewAppointment:"/view-appointment",
    viewCategory:"/view-category",
    addMember:"/add-member",
    editUser:"/edit-detail",
    uploadReport:"/upload-report",
    viewQuestion:"/view-question",
    applyCoupon: "/apply-coupon",
   };
   
   export default endUrls;