import axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useParams } from "react-router-dom";
import YoutubeSection from "../../sections/YoutubeSection";
import Footer from "../Footer";

function StaticPages() {
  const [headerData, setHeaderData] = useState([]);
  const params = useParams();
  const path = encodeURIComponent(params?.slug);
  const isMobile = window.innerWidth <= 767;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGetHeader = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `https://api.healinroots.com/api/website/page/${path}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(
        "response?.data?.data?.pageData",
        response?.data?.data?.pageData
      );
      setHeaderData(response?.data?.data?.pageData);
    } catch (error) {
      console.log("Error fetching Pages", error);
    }
  };

  useEffect(() => {
    handleGetHeader();
  }, [path]);

  // Function to split content into parts (text, iframes, headings)
  // Function to split content into sections (heading + related content)
  const splitIntoSections = (content) => {
    // Regex to split content by headings (h1-h6) and group their following content
    const parts = content.split(/(<h[1-6][^>]*>.*?<\/h[1-6]>)/g);

    let sections = [];
    let currentSection = null;

    parts.forEach((part) => {
      if (part.startsWith("<h")) {
        // If it's a heading, start a new section
        if (currentSection) sections.push(currentSection); // Push previous section
        currentSection = { heading: part, content: "" }; // Create new section
      } else if (currentSection) {
        // Append the content to the current section
        currentSection.content += part;
      }
    });

    if (currentSection) sections.push(currentSection); // Push the last section

    return sections;
  };

  // Function to render sections with alternating background colors
  const renderContent = (content) => {
    if (!content) return null;

    // Decode HTML entities
    const decodedContent = content.replace(/&lt;/g, "<").replace(/&gt;/g, ">");

    // Split content into sections (heading + content)
    const sections = splitIntoSections(decodedContent);

    // Determine if viewport is mobile
    const isMobile = window.innerWidth <= 767;

    // Inline styles for section background color alternation
    const sectionStyle = (index) => ({
      backgroundColor: index % 2 === 0 ? "white" : "#f0f0f0",
      padding: isMobile ? "10px" : "20px",
      fontFamily: "Montserrat, sans-serif",
      textAlign: "justify",
      paddingInline: "10%",
      marginBottom: isMobile ? "10px" : "40px",
    });

    const headingStyle = {
      fontFamily: "Montserrat, sans-serif",
      marginBottom: isMobile ? "10px" : "20px",
    };

    return (
      <>
        {sections.map((section, index) => (
          <div key={index} style={sectionStyle(index)}>
            <div
              className="heading-container"
              style={headingStyle}
              dangerouslySetInnerHTML={{ __html: section.heading }}
            />
            <div
              className="content-container"
              dangerouslySetInnerHTML={{ __html: section.content }}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <>
      <Header />
      <div className="" style={{ paddingTop: isMobile ? "15%" : "7%" }}>
        <div className="">
          {/* <div className='lg:px-24 max-md:px-8 md:px-10 max-sm:px-6 py-5'> */}
          {renderContent(headerData?.content)}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default StaticPages;
