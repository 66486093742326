import { useEffect, useState } from "react";
import Table from "../ScheduleManagement/Table";
import styles from "./ScheduleManagement.module.css";
import { fetchAppointments } from "../../../utils/apis.utils";
import { fetchAppointment } from "../../../utils/global.apis";
import moment from "moment";


const AppointmentManagement = (props)=>{

    const [data,setData]=useState([
        ["Name",'Phone',"Email","Date","Time"],
        ["Ashish",'1234567890',"healinroots@gmail.com","10-10-2023","10:00-12:00"],
    ]);

    useEffect(()=>{fetchAllApointment()},[])

    const fetchAllApointment = async () => {
        const response = await fetchAppointment()
        console.log(response.data.data)
        let tempData = [["Name",'Phone',"Email","Date","Time"]]
        response.data.data.map(el=>{
            console.log(el)
            let phone = el.user.isParent?el?.user?.phone:el?.user?.parent?.phone
            let email = el.user.isParent?el?.user?.email:el?.user?.parent?.email
            tempData.push([el.user.name,phone,email,moment(el.date).format("DD-MM-YYYY"),`${el.from}-${el.to}`])
        })
        setData(tempData)
    }
    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            <div className={styles["headings"]}>
                <h1>Appointment management</h1>
            </div>
            <div className={styles["table"]}>
                <div className={styles["tab"]}>
                    <Table data={data} makeLastBtn={false}/>
                </div>
            </div>
        </div>
    </div>;
}

export default AppointmentManagement;