import React, { useEffect, useRef, useState } from "react";
import { fetchAnswersById, fetchUserById, getAllAttempt } from "../../../utils/global.apis";
import styles from "./attemptManagement.module.css";
import AttemptTable from "./Table";
import moment from "moment";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useReactToPrint } from 'react-to-print';
import Question from "../MemberDetails/Question";
import { useSearchParams } from "react-router-dom";

const AttemptManagement = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [redirect, setRedirect] = useState(false)
    const [attemptData, setAttemptData] = useState([])
    const [showAnswers, setShowAnswers] = useState(false)
    const [answers, setAnswers] = useState([]);
    const question = useRef(null)
    const [ids, setIds] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    useEffect(() => {
        fetchData()
    }, [])
    const handlePrint = useReactToPrint({
        content: () => question.current,
    });
    const fetchData = async () => {
        const { data } = await getAllAttempt();
        let temp = []
        data.data.attempt.map(el => {
            if (el.user) {
                let email = el.user.parent ? el.user.parent?.email : el.user.email
                let phone = el.user.parent ? el.user.parent?.phone : el.user.phone
                temp.push([
                    el.user._id,
                    el.user.referenceId,
                    el.user.name,
                    phone,
                    el.user.websiteUser?"Yes":"No",
                    "User Answers",
                    email,
                    moment(el.updatedAt).format("DD-MM-YYYY"),
                ])
            }
        })
        setAttemptData(temp)
    }

    useEffect(() => {
        fetchUserById(searchParams.get('id'))
            .then(res => {
                if (!res.data.data) throw `something went wrong!`;

                // console.log(res.data.data);
                let status;
                if (res.data.data.isAdmin) status = "Admin";
                if (res.data.data.isSubscribed) status = "Member";
                else status = "User";

                setUserDetails({ ...res.data.data, status });
            })
            .catch(err => console.log(err));
        fetchAnswersById(searchParams.get('id'))
            .then(res => {
                if (!res.data.data) {
                    throw `something went wrong!`
                }

                setAnswers(formatAnswersArr(res.data.data.answers));
            })
            .catch(err => {
                setAnswers([])
            });
    }, [searchParams.get('id'), redirect]);

    const formatAnswersArr = (arr) => {
        return arr.map(el => {
            let qtype = "Yes / No";
            if (el.question.type == "input") qtype = "Text";
            if (el.question.type == "radio") qtype = "Radio";
            if (el.question.type == "checkbox") qtype = "Checkbox";
            if (el.question.type == "bool") qtype = "Yes / No";

            let cat;
            if (el.question.category == "I") cat = "Indian";
            if (el.question.category == "B") cat = "Both";
            if (el.question.category == "OI") cat = "Non - Indian";

            let ques = [];
            // if(el.question.language=="E"){
            ques = [
                {
                    language: 'English',
                    question: el.question.question,
                    options: el.question.options,
                    answer: [el.answer]
                }
            ]
            // }
            // else if(el.question.language=="H"){
            //     ques=[
            //         {
            //             language:'Hindi',
            //             question:el.question.question,
            //             options:el.question.options,
            //             answer:[el.answer]
            //         }
            //     ]
            // }
            // else if(el.question.language=="B"){
            //     ques=[
            //         {
            //             language:'English',
            //             question:el.question.question,
            //             options:el.question.options,
            //             answer:[el.answer]
            //         },
            //         {
            //             language:'Hindi',
            //             question:el.question.hindiQuestion,
            //             options:el.question.hindiOptions,
            //             answer:[el.hindiAnswer]
            //         },
            //     ];
            // }

            return {
                qno: el._id,
                qtype,
                question: ques,
                category: cat
            }
        })
    }

    return (
        <>
            <div className={styles["container"]}>
                {
                    !showAnswers && <div className={styles["content"]}>
                        <div className={styles["headings"]}>
                            <h1>Attempt management</h1>
                        </div>
                        <div className={styles["inner"]} style={{ marginTop: "40px" }}>
                            <AttemptTable notFoundMsg="No answers found" data={attemptData} some="abs" setRedirect={setRedirect} redirect={redirect} ids={ids} setShowCA={setShowAnswers} />
                        </div>
                    </div>
                }
                {
                    showAnswers && <div className={styles["content"]}>
                        <div className={styles["back"]} style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}>
                            <div className={styles["close"]} onClick={(e) => setShowAnswers(false)}><AiOutlineArrowLeft /></div>
                            <div onClick={() => { handlePrint() }}>
                                <img
                                    src={require("../../../assets/printer.png")}
                                    style={{
                                        width: "50px",
                                        height: "50px"
                                    }}
                                />
                            </div>
                        </div>
                        <div className={styles["scontent"]} ref={question}>
                            <div className={styles["m_heading"]}>
                                <h3>User Answers</h3>
                            </div>
                            <div ref={question}>
                                <div className={styles["userdetails"]}>
                                    <div className={styles["ud_content"]}>
                                        <div className={styles["data"]}>
                                            <span><b>Id:</b> {userDetails?.referenceId}</span>

                                        </div>
                                        <div className={styles["data"]}>
                                            <span><b>Name:</b> {userDetails.name}</span>
                                        </div>
                                    </div>
                                </div>
                                {answers && answers.map((el, idx) => <div style={{ backgroundColor: 'white', marginBottom: '20px' }}><Question {...answers[idx]} qno={idx + 1} key={idx} hideCollapse={true} /></div>)}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default AttemptManagement;