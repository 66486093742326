import React, { useEffect, useState } from "react";
import { FaPlus, FaLink, FaMinus } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FooterForm = ({showModal, handleCloseModal, allData, handleFooter}) => {
  const [formData, setFormData] = useState({
    footerLinks: [
      {
        title: "",
        status: true,
        link: [
          {
            linkName: "",
            linkUrl: "",
          },
        ],
      },
    ],
    socialMedia: [
      {
        linkName: "",
        linkUrl: "",
      },
    ],
    footerBottomLine: "",
    address: "",
  });

  useEffect(() => {
    if (allData) {
      setFormData({
        footerLinks: allData.footerLinks || [
          {
            title: "",
            status: true,
            link: [{ linkName: "", linkUrl: "" }],
          },
        ],
        socialMedia: allData.socialMedia || [{ linkName: "", linkUrl: "" }],
        footerBottomLine: allData.footerBottomLine || "",
        address: allData.address || "",
      });
    }
  }, [allData]);
  const handleChange = (e, index, type, linkIndex = null) => {
    const { name, value } = e.target;

    if (type === "footerLinks") {
      const updatedFooterLinks = [...formData.footerLinks];
      if (linkIndex !== null) {
        updatedFooterLinks[index].link[linkIndex][name] = value;
      } else {
        updatedFooterLinks[index][name] = value;
      }
      setFormData({ ...formData, footerLinks: updatedFooterLinks });
    } else if (type === "socialMedia") {
      const updatedSocialMedia = [...formData.socialMedia];
      updatedSocialMedia[index][name] = value;
      setFormData({ ...formData, socialMedia: updatedSocialMedia });
    } else if (type === "footerBottomLine") {
      setFormData({ ...formData, footerBottomLine: value });
    } else if (type === "address") {
      setFormData({ ...formData, address: value });
    }
  };

  const addFooterLink = () => {
    setFormData({
      ...formData,
      footerLinks: [
        ...formData.footerLinks,
        {
          title: "",
          status: true,
          link: [
            {
              linkName: "",
              linkUrl: "",
            },
          ],
        },
      ],
    });
  };

  const addLink = (index) => {
    const updatedFooterLinks = [...formData.footerLinks];
    updatedFooterLinks[index].link.push({ linkName: "", linkUrl: "" });
    setFormData({ ...formData, footerLinks: updatedFooterLinks });
  };

  const addSocialMedia = () => {
    setFormData({
      ...formData,
      socialMedia: [...formData.socialMedia, { linkName: "", linkUrl: "" }],
    });
  };

  const removeFooterLink = (index) => {
    const updatedFooterLinks = [...formData.footerLinks];
    updatedFooterLinks.splice(index, 1);
    setFormData({ ...formData, footerLinks: updatedFooterLinks });
  };

  const removeLink = (index, linkIndex) => {
    const updatedFooterLinks = [...formData.footerLinks];
    updatedFooterLinks[index].link.splice(linkIndex, 1);
    setFormData({ ...formData, footerLinks: updatedFooterLinks });
  };

  const removeSocialMedia = (index) => {
    const updatedSocialMedia = [...formData.socialMedia];
    updatedSocialMedia.splice(index, 1);
    setFormData({ ...formData, socialMedia: updatedSocialMedia });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.post(
        "https://api.healinroots.com/api/footer",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      console.log("Response Data:", response.data);
      handleFooter();
      handleCloseModal();
      toast.success(response.data.message || "Footer updated successfully");
    } catch (error) {
      toast.success(error.response.data.message || "Failed updaing footer");
      console.error("Error submitting form:", error);
    }
  };

  return (<>
  <ToastContainer/>
          {showModal && ( 
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 99,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "10px",
          width: "700px",
          maxHeight: "90vh",
          overflowY: "auto",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <form onSubmit={handleSubmit}>
          <h2 style={{ textAlign: "center", color: "#333" }}>
            Add Footer Details
          </h2>

          {/* Footer Links */}
          <div
            style={{
              borderBottom: "2px solid #ddd",
              marginBottom: "20px",
              paddingBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <h3 style={{ color: "#555", marginBlock: "auto" }}>Footer Links</h3>
              <button
                type="button"
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  padding: "5px 10px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={addFooterLink}
              >
                Add Footer Link
              </button>
            </div>

            {formData.footerLinks.map((footerLink, index) => (
              <div
                key={index}
                style={{ marginBottom: "20px", paddingBottom: "15px" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="text"
                    name="title"
                    placeholder="Footer Link Title"
                    value={footerLink.title}
                    onChange={(e) =>
                      handleChange(e, index, "footerLinks")
                    }
                    style={{
                      marginRight: "10px",
                      padding: "10px",
                      flex: 1,
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => addLink(index)}
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      padding: "5px 10px",
                      backgroundColor: "#28a745",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    <FaPlus/>
                  </button>
                  <button
                    type="button"
                    onClick={() => removeFooterLink(index)}
                    style={{
                      cursor: "pointer",
                      fontSize: "16px",
                      padding: "5px 10px",
                      backgroundColor: "red",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      marginLeft: "10px",
                    }}
                  >
                    <FaMinus />
                  </button>
                </div>

                {footerLink.link.map((linkItem, linkIndex) => (
                  <div
                    key={linkIndex}
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      alignItems: "center",
                    }}
                  >
                    <FaLink style={{ color: "#007bff", marginRight: "10px" }} />
                    <input
                      type="text"
                      name="linkName"
                      placeholder="Link Name"
                      value={linkItem.linkName}
                      onChange={(e) =>
                        handleChange(e, index, "footerLinks", linkIndex)
                      }
                      style={{
                        marginRight: "10px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        flex: 1,
                      }}
                    />
                    <input
                      type="text"
                      name="linkUrl"
                      placeholder="Link URL"
                      value={linkItem.linkUrl}
                      onChange={(e) =>
                        handleChange(e, index, "footerLinks", linkIndex)
                      }
                      style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        flex: 1,
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => removeLink(index, linkIndex)}
                      style={{
                        cursor: "pointer",
                        fontSize: "16px",
                        padding: "5px 5px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "50px",
                        marginLeft: "10px",
                      }}
                    >
                      <FaMinus />
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </div>

          {/* Social Media Links */}
          <div
            style={{
              borderBottom: "2px solid #ddd",
              marginBottom: "20px",
              paddingBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ color: "#555" }}>Social Media Links</h3>
              <button
                type="button"
                style={{
                  cursor: "pointer",
                  fontSize: "16px",
                  padding: "5px 10px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={addSocialMedia}
              >
                Add Social Media
              </button>
            </div>

            {formData.socialMedia.map((socialMediaItem, index) => (
              <div
                key={index}
                style={{ display: "flex", marginTop: "10px", alignItems: "center" }}
              >
                <input
                  type="text"
                  name="linkName"
                  placeholder="Platform Name"
                  value={socialMediaItem.linkName}
                  onChange={(e) =>
                    handleChange(e, index, "socialMedia")
                  }
                  style={{
                    marginRight: "10px",
                    padding: "5px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    flex: 1,
                  }}
                />
                <input
                  type="text"
                  name="linkUrl"
                  placeholder="Platform URL"
                  value={socialMediaItem.linkUrl}
                  onChange={(e) =>
                    handleChange(e, index, "socialMedia")
                  }
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    flex: 1,
                  }}
                />
                <button
                  type="button"
                  onClick={() => removeSocialMedia(index)}
                  style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    padding: "5px 5px",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "50px",
                    marginLeft: "10px",
                  }}
                >
                  <FaMinus />
                </button>
              </div>
            ))}
          </div>

          {/* Footer Bottom Line */}
          <div>
            <h3 style={{ color: "#555" }}>Address</h3>
            <textarea
              name="address"
              value={formData.address}
              onChange={(e) => handleChange(e, null, "address")}
              placeholder="Enter address"
              rows="3"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>
          <div>
            <h3 style={{ color: "#555" }}>Footer Bottom Line</h3>
            <textarea
              name="footerBottomLine"
              value={formData.footerBottomLine}
              onChange={(e) => handleChange(e, null, "footerBottomLine")}
              placeholder="Footer Bottom Line Text"
              rows="3"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            />
          </div>

          {/* Submit Button */}
          {/* <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              type="submit"
              style={{
                cursor: "pointer",
                fontSize: "16px",
                padding: "10px 20px",
                backgroundColor: "#28a745",
                color: "white",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Submit Footer
            </button>
          </div> */}
          <div style={{ display: "flex", justifyContent: "center" }}>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                  background: "var(--green)",
                 border: "none",
                 borderRadius: "5px",
                 padding: "5px 10px",
                 color: "white",
                 width:"100px",
                 justifyContent:"center",
                 cursor: "pointer"
               }}
               type="submit"
             >
               Submit
             </button>
             <button
               style={{
                 display: "flex",
                 margin: "10px",
                 fontSize: "16px",
                 background: "#d3d3d3",
                 border: "none",
                 borderRadius: "5px",
                 width:"100px",
                 justifyContent:"center",
                 padding: "5px 10px",
                 color: "black",
                 cursor: "pointer"
               }}
               onClick={handleCloseModal}
             >
               Cancel
             </button>
           </div>
        </form>
      </div>
    </div>
              )}</>

  );
};

export default FooterForm;
