import axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom'; 
import Header from '../Header';
import Footer from '../Footer';

const BlogDetail = ({ blogData = {} }) => {
    const { blogId, postId } = useParams();
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true); 

    const handleBlog = async () => {
        try {
            const authToken = localStorage.getItem("jwt");
            const response = await axios.get(
                `https://api.healinroots.com/api/blog/post?blogId=${blogId}&postId=${postId}`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            console.log("Blog Detail Fetched successfully", response.data.data);
            setAllData(response.data.data);
        } catch (error) {
            console.log("Error fetching blogs", error);
        }  finally {
          setLoading(false); 
        }
    };

    useEffect(() => {
        handleBlog();
        window.scrollTo(0,0);
    }, [blogId, postId]);

    const formattedDate = moment(allData?.published).format('MMMM D, YYYY'); 

    useEffect(() => {
        const images = document.querySelectorAll('.separator img');
        images.forEach((img) => {
            img.style.display = 'block';
            img.style.margin = '20px auto';
            img.style.maxWidth = '100%';
            img.style.height = 'auto';
        });
    }, [allData]);

    return (
      <>
        <Header />
        <div style={styles.container}>
        {loading ? (
    <div style={styles.loader}>Loading...</div> 
) : (
    <>
        <h1 style={styles.title}>{allData?.title}</h1>
        {allData?.published && <p style={styles.date}>{formattedDate}</p>}
        <div
            style={styles.content}
            dangerouslySetInnerHTML={{ __html: allData?.content }}
        />
    </>
)}

        </div>
        <Footer />
      </>
    );
};

const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      paddingTop: '100px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
      minHeight: '100vh',
      position:'relative', 
    },
    title: {
      fontSize: '2em',
      marginBottom: '10px',
    },
    date: {
      fontSize: '1em',
      marginBottom: '20px',
      color: 'grey',
    },
    content: {
      marginBottom: '20px',
    },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#007bff',
  }
  
};

export default BlogDetail;
