import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAdd } from "react-icons/md";
import FooterForm from './FooterForm';

function AdminFooter() {
  const [allData, setAllData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const handleFooter = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `https://api.healinroots.com/api/footer`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Footer Fetched successfully", response.data.data.footer);
      setAllData(response.data.data.footer);
    } catch (error) {
      console.log("Error fetching footer", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFooter();
  }, []);

  const handleAddDataClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div style={{ background: "#eaeaea", width: "100%", height: "100%" }}>
      <div style={{ width: "80%", marginInline: "auto", paddingTop: "50px", background: "#eaeaea" }}>
        <ToastContainer />
        <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", marginBottom: "20px" }}>
          <h1 style={{ display: "flex", fontSize: "calc(1.375rem + 1.5vw)", fontWeight: "600", justifyContent: "start", alignItems: "center" }}>
            Footer
          </h1>
          <div style={{ display: "flex", marginLeft: "auto", marginRight: "10px" }}>
            <button
              style={{
                background: "var(--green)",
                fontSize: "18px",
                border: "none",
                color: "white",
                padding: "5px 20px",
                display: "flex",
                alignItems: "center"
              }}
              onClick={handleAddDataClick}
            >
              Edit
            </button>
          </div>
        </div>

        <div style={{ width: "100%", overflow: "scroll", maxHeight: "70vh", paddingInline: "20px", backgroundColor: "#F8F9FA" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <thead style={{ position: "sticky", top: "0", backgroundColor: "#F8F9FA" }}>
              <tr style={{ fontSize: "18px", fontWeight: "600", paddingTop: "15px" }}>
                <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }}>Footer Links</th>
                <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }}>Social Media</th>
                <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }}>Address</th>
                <th style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }}>Bottom Line</th>
              </tr>
            </thead>
            <tbody style={{ overflowY: "auto" }}>
              {loading ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center", padding: "20px" }}>Loading...</td>
                </tr>
              ) : !allData._id ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center", padding: "20px" }}>No data available.</td>
                </tr>
              ) : (
                <tr style={{ background: "#F8F9FA" }}>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", fontFamily: "Montserrat" }}>
                    {allData?.footerLinks?.map((linkGroup, groupIndex) => (
                      <div key={groupIndex}>
                        <strong>{linkGroup?.title}</strong>
                        <ul>
                          {linkGroup?.link?.map((link, linkIndex) => (
                            <li key={linkIndex}>
                              {link?.linkName} - <a href={link.linkUrl} target="_blank" rel="noopener noreferrer">{link?.linkUrl}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", fontFamily: "Montserrat" }}>
                    {allData?.socialMedia?.map((social, socialIndex) => (
                      <div key={socialIndex}>
                        <strong>{social?.linkName}</strong> - <a href={social?.linkUrl} target="_blank" rel="noopener noreferrer">{social?.linkUrl}</a>
                      </div>
                    ))}
                  </td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", fontFamily: "Montserrat" }}>{allData?.address}</td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "left", padding: "8px", fontFamily: "Montserrat" }}>{allData?.footerBottomLine}</td>
                 
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Add/Edit data modal */}
        <FooterForm showModal={showModal} handleCloseModal={handleCloseModal} allData={allData} handleFooter={handleFooter}/>
      </div>
    </div>
  );
}

export default AdminFooter;
