import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin5Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdAdd } from "react-icons/md";

function Header() {
  const [headerPages, setHeaderPages] = useState([]);
  const [staticId, setStaticId] = useState([]);
  const [selectStaticId, setSelectStaticId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [staticPageView, setStaticPageView] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [formData, setFormData] = useState({
    id: '',
    name: "",
    type: "",
    link: "",
    staticPage: null
  });

  const handleGetIdOfStaticPages = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `https://api.healinroots.com/api/website/page`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setStaticId(response?.data?.data?.webSitePagesData);
    } catch (error) {
      console.log("Error fetching Pages", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleGetIdOfStaticPages();
  }, []);


  const handleHeader = async () => {
    try {
      const authToken = localStorage.getItem("jwt");
      const response = await axios.get(
        `https://api.healinroots.com/api/website/header`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setHeaderPages(response.data.data.headersData);
    } catch (error) {
      console.log("Error fetching Header", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleHeader();
  }, []);

  const handleAddDataClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setFormData({
      id: "",
      name: "",
      type: "",
      link: "",
      staticPage: ""
    });
    setShowModal(false);
    setEditModal(false);
    setDeleteModal(false);
    // setSelected(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === 'type') {
      setFormData({
        ...formData,
        [name]: value,
        link: '', 
        staticPage:'',
      });
    } else if (name === 'staticPage') {
      const selectedOption = staticId.find(i => i._id === value);
      if (selectedOption) {
        setFormData({
          ...formData,
          staticPage: selectedOption._id, 
          link: `${selectedOption.slug}`, 
          // link: `staticPages/${selectedOption.slug}`, 
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  

  const handleForm = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");

    if (!authToken) {
      toast.error("No authentication token found. Please log in.");
      return;
    }
    
    try {
      const response = await axios.post(
        `https://api.healinroots.com/api/website/header`,
        {
          name: formData.name,
          type: formData.type,
          link: formData.link,
          staticPage: formData.staticPage,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Header added successfully");
      handleHeader();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed adding Header");
    }

  };

  // edit entry
  const handleEditClick = (data) => {
    setFormData({
      id: data._id || "",
      name: data.name || "",
      type: data.type || "",
      link: data.link || "",
      staticPage: data?.staticPage?._id || "",
    });
    setEditModal(true);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("jwt");
    if (!formData.id) {
      toast.error("Error updating data");
      return;
    }

    try {
      const response = await axios.put(
        `https://api.healinroots.com/api/website/header/${formData.id}`,
        {
          name: formData.name,
          type: formData.type,
          link: formData.link,
          staticPage: formData.staticPage,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Header updated successfully");
      handleHeader();
      handleCloseModal();
    } catch (error) {
      toast.error("Failed updating Header");
    }

  };
  //   Delete API
  const handleDeleteClick = (id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  const handleDelete = async () => {
    try {
      const authToken = localStorage.getItem("jwt");

      const response = await axios.delete(
        `https://api.healinroots.com/api/website/header/${deleteId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast.success(" Header Deleted Successfully");
      handleHeader();
      setDeleteId("");
      handleCloseModal();
    } catch (error) {
      toast.error("Failed Deleting Header")
    }
  };

  return (
  <div style={{ width: "100%", height: "100%" }}>
    <div style={{ width: "100%", marginInline: "auto", paddingTop: "50px" }}>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          marginBottom: "20px"
        }}
      >
        <h1
          style={{
            display: "flex",
            fontSize: "calc(1.375rem + 1.5vw)",
            fontWeight: "600",
            justifyContent: "start",
            alignItems: "center",

          }}
        >
          Top Menu{" "}
        </h1>
        <div style={{ display: "flex", marginLeft: "auto", marginRight: "10px" }}>
          <button style={{
            background: "var(--green)",
            fontSize: "18px",
            border: "none",
            color: "white",
            padding: "5px 20px",
            display: "flex",
            alignItems: "center"
          }}
            onClick={handleAddDataClick}
          >Add Menu</button>
        </div>
      </div>
      <div style={{ width: "100%", overflow: "scroll", maxHeight: "70vh",height:'max-content', paddingInline: "20px", backgroundColor: "#F8F9FA" }}>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead style={{ position: "sticky", top: "-1px" }}>
            <tr style={{ fontSize: "18px", fontWeight: "600", paddingTop: "15px" }}>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>ID</th>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>Name</th>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>type</th>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>Link</th>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>Static Page</th>
              <th style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "10px", backgroundColor: "#F8F9FA", fontFamily: "Montserrat" }} className='whitespace-nowrap'>Action</th>
            </tr>
          </thead>
          <tbody style={{ overflowY: "auto" }}>
            {loading ? (
              <tr>
                <td colSpan="4" style={{ textAlign: "center", padding: "20px" }}>Loading...</td>
              </tr>
            ) : headerPages?.length === 0 ? (
              <tr>
                <td colSpan="4" style={{ textAlign: "center", padding: "20px" }}>No Header available.</td>
              </tr>
            ) : (
              headerPages?.map((data, index) => (
                <tr key={index} style={{ background: "#F8F9FA" }}>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>{data?._id}</td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>{data?.name}</td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>{data?.type}</td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>
                    {
                      data?.link ?
                        data?.link
                        :
                        'N/A'
                    }
                  </td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>
                    {
                      data?.staticPage ?
                        <button onClick={() => setStaticPageView(index)} className='text-indigo-700'>View</button>
                        :
                        'N/A'
                    }
                    {/* View static page details*/}
                    {index === staticPageView && (
                     <div
                     className="fixed inset-0 z-50 overflow-auto flex items-center justify-center  bg-gray-900 bg-opacity-25 backdrop-blur-sm"
                   >
                     <div
                       className="bg-white rounded-xl flex flex-col p-8 overflow-auto justify-center" style={{ width: "100vh",height:"80%" }}
                     >
                      <div className='overflow-auto'>
                      <div className='flex gap-2 '>
                            <label style={{ display: "flex", alignItems: "cente" }} className='font-semibold '>Name:</label>
                            <div>{data?.staticPage?.pageName}</div>
                          </div>
                          <div className='flex gap-2 '>
                            <label style={{ display: "flex", alignItems: "cente" }} className='font-semibold '>Slug:</label>
                            <div>{data?.staticPage?.slug}</div>
                          </div>
                          <div className='flex gap-2 '>
                            <label style={{ display: "flex", alignItems: "cente" }} className='font-semibold '>Content:</label>
                            <div dangerouslySetInnerHTML={{ __html: data?.staticPage?.content }}></div>
                          </div>

                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "#d3d3d3", width: "100px", justifyContent: "center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "black", cursor: "pointer" }} onClick={() => setStaticPageView(null)}>Close</button>
                          </div>
                      </div>
                         
                        </div>
                      </div>
                    )}
                  </td>
                  <td style={{ borderBottom: "1px solid #dddddd", textAlign: "center", padding: "8px", paddingBlock: "8px", fontFamily: "Montserrat" }}>
                    <div style={{ display: "flex", flexDirection: "row" }} className='justify-center'>
                      <FaRegEdit size={20} color={"var(--green)"} style={{ marginInline: "5px", display: "flex" }} onClick={() => handleEditClick(data)} />
                      <RiDeleteBin5Line size={20} color={"red"} style={{ marginLeft: "10px" }} onClick={() => handleDeleteClick(data._id)} />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {/* Add new data */}
      {showModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
            overflow:'auto',
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "450px",
            }}
          >
            <form onSubmit={(e) => { handleForm(e) }}>
              <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Add Menu</div>

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Name</label>
                <input
                  type="text"
                  style={{ margin: "10px", padding: "5px", width: "100%", border: "1px solid #d3d3d3", borderRadius: "2px", fontWeight: "400" }}
                  placeholder="Enter name"
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  required
                />
              </div>

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Type</label>
                <select onChange={handleChange} value={formData.type} name='type' style={{ margin: "10px", padding: "5px", width: "100%", border: "1px solid #d3d3d3", borderRadius: "2px", fontWeight: "400" }}
                >
                  <option value=''>Select</option>
                  <option value='static'>Static</option>
                  <option value='dynamic'>Dynamic</option>

                </select>
              </div>
              {formData.type === 'dynamic' && (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
    <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Link</label>
    <input
      type="text"
      style={{ margin: "10px", padding: "5px", width: "100%", border: "1px solid #d3d3d3", borderRadius: "2px", fontWeight: "400" }}
      placeholder="Enter Link"
      value={formData.link}
      name="link"
      onChange={handleChange}
    />
  </div>
)}

{formData.type === 'static' && (
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
    <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Static page</label>
    <select onChange={handleChange} value={formData.staticPage} name='staticPage' style={{ margin: "10px", padding: "5px", width: "100%", border: "1px solid #d3d3d3", borderRadius: "2px", fontWeight: "400" }}>
      <option value=''>Select</option>
      {staticId?.map(i => (
        <option key={i?._id} value={i?._id}>{i?.pageName}</option>
      ))}
    </select>
  </div>
)}


              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{
                    display: "flex",
                    margin: "10px",
                    fontSize: "16px",
                    background: "var(--green)",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    color: "white",
                    width: "100%",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}
                  type="submit"
                >
                  Add
                </button>
                <button
                  style={{
                    display: "flex",
                    margin: "10px",
                    fontSize: "16px",
                    background: "#d3d3d3",
                    border: "none",
                    borderRadius: "5px",
                    width: "100%",
                    justifyContent: "center",
                    padding: "5px 10px",
                    color: "black",
                    cursor: "pointer"
                  }}
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>

      )}
      {/* Update data */}
      {editModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "450px",
            }}
          >
            <form onSubmit={(e) => { handleUpdate(e) }}>
              <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Edit Menu</div>

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Name</label>
                <input
                  type="text"
                  style={{ margin: "10px", padding: "5px", width: "100%", border: "1px solid #d3d3d3", borderRadius: "2px", fontWeight: "400" }}
                  placeholder="Enter Name"
                  value={formData.name}
                  name="name"
                  onChange={handleChange}
                  required
                />
              </div>

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Type</label>
                <select onChange={handleChange} value={formData.type} name='type' style={{ margin: "10px", padding: "5px", width: "100%", border: "1px solid #d3d3d3", borderRadius: "2px", fontWeight: "400" }}
                >
                  <option value=''>Select</option>
                  <option value='static'>Static</option>
                  <option value='dynamic'>Dynamic</option>

                </select>
              </div>
              {formData.type === 'dynamic' && (

              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Link</label>
                <input
                  type="text"
                  style={{ margin: "10px", padding: "5px", width: "100%", border: "1px solid #d3d3d3", borderRadius: "2px", fontWeight: "400" }}
                  placeholder="Enter Link"
                  value={formData.link}
                  name="link"
                  onChange={handleChange}
                  // disabled={formData.type === 'static'}
                  // className={`${formData.type === 'static' ? 'disabled:opacity-75' : ''}`}
                />
              </div>)}

              {formData.type === 'static' && (
   
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <label style={{ width: "40%", display: "flex", alignItems: "center", margin: "auto", padding: "5px" }}>Static page</label>
                <select onChange={handleChange}
                  // disabled={formData.type === 'dynamic'}
                  // className={`${formData.type === 'dynamic' ? 'disabled:opacity-75' : ''}`}
                  value={formData.staticPage} name='staticPage' style={{ margin: "10px", padding: "5px", width: "100%", border: "1px solid #d3d3d3", borderRadius: "2px", fontWeight: "400" }}
                >
                  <option value=''>Select</option>
                  {
                    staticId?.map(i =>
                      <option value={i?._id}>{i?.pageName}</option>
                    )
                  }
                </select>
              </div>)}


              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  style={{
                    display: "flex",
                    margin: "10px",
                    fontSize: "16px",
                    background: "var(--green)",
                    border: "none",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    color: "white",
                    width: "100%",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}
                  type="submit"
                >
                  Update
                </button>
                <button
                  style={{
                    display: "flex",
                    margin: "10px",
                    fontSize: "16px",
                    background: "#d3d3d3",
                    border: "none",
                    borderRadius: "5px",
                    width: "100%",
                    justifyContent: "center",
                    padding: "5px 10px",
                    color: "black",
                    cursor: "pointer"
                  }}
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>

      )}

      {/* Delete Entry */}
      {deleteModal && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "5px",
              width: "450px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: 600, marginBottom: "10px" }}>Are you sure you want to delete?</div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "var(--green)", width: "100px", justifyContent: "center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "white", cursor: "pointer" }} onClick={handleDelete}>Delete</button>
              <button style={{ display: "flex", margin: "10px", fontSize: "16px", background: "#d3d3d3", width: "100px", justifyContent: "center", border: "none", borderRadius: "5px", padding: "5px 10px", color: "black", cursor: "pointer" }} onClick={handleCloseModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}

    </div>
  </div>
  )
}

export default Header