// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import $ from 'jquery';
// import Popper from 'popper.js';

// import "./../../../custom.css";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SideNav from "../../elements/SideNav";
import MemberDetails from "../../sections/MemberDetails";
import QuestionaireManagement from "../../sections/QuestionaireManagement";
import ChartQuestionManagement from "../../sections/ChartQuestionManagement";
import styles from "./Dashboard.module.css";
import ScheduleManagement from "../../sections/ScheduleManagement";
import AppointmentManagement from "../../sections/AppointmentManagement";
import MembershipManagement from "../../sections/MembershipManagement";
import ChartAttempt from "../../sections/ChartAttempt";
import ReferAndEarn from "../../sections/ReferAndEarn";
import Coupon from "../../sections/Coupon";
import AttemptManagement from "../../sections/AttemptManagement";
import FAQ from "../../sections/FAQ/FAQ"
import KnowMore from "../../sections/KnowMore/KnowMore";
import DiseasesWeCure from "../../sections/DiseasesWeCure/DiseasesWeCure";
import Static from "../../sections/Static pages/Static";
import Header from "../../sections/HeaderSection/Header";
import AdminFooter from "../../sections/AdminFooter/AdminFooter";
import Services from "../../sections/ServicesAdmin/Services";
import HeroSection from "../../sections/HeroSections/HeroSections";
import MediaCenter from "../../sections/MediaCenter/MediaCenter";
const Dashboard = (props)=>{
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeIdx,setActiveIdx] = useState(searchParams.get('activeIdx')?searchParams.get('activeIdx'):0);

    // console.log(activeIdx);
    const navData=[
        {
            title:"User management",
            element:<MemberDetails/>
        },
        {
            title:"Make A Chart",
            element:<ChartAttempt/>
        },
        {
            title:"Questionnaire management",
            element:<QuestionaireManagement/>
        },
        {
            title:"Attempt management",
            element:<AttemptManagement/>
        },
        {
            title:"DietChart Questionnaire",
            element:<ChartQuestionManagement/>
        },
        {
            title:"Scheduling Management",
            element:<ScheduleManagement/>
        },
        {
            title:"Apointment Management",
            element:<AppointmentManagement/>
        },
        {
            title:"Membership management",
            element:<MembershipManagement/>
        },
        // {
        //     title:"Refer And Earn",
        //     element:<ReferAndEarn/>
        // }
        {
            title:"Coupon management",
            element:<Coupon/>
        },
        {
            title:"Diseases We Cure",
            element:<DiseasesWeCure/>
        },
        {
            title:"Know more",
            element:<KnowMore/>
        },
        {
            title:"FAQ",
            element:<FAQ/>
        },
        {
            title:"Static Pages",
            element:<Static/>
        },
        {
            title:"Top Menu",
            element:<Header/>
          },
        {
            title:"Hero Section",
            element:<HeroSection/>
          },
        {
            title:"Media Center",
            element:<MediaCenter/>
          },
        {
            title:"Services",
            element:<Services/>
          },
          {
            title:"Footer",
            element:<AdminFooter/>
        },
    ];

    return <div className={styles["container"]}>
        <div className={styles["content"]}>
            <div className={styles["sidebar"]}>
                <SideNav navData={navData} activeIdx={activeIdx} setActiveIdx={setActiveIdx}/>
            </div>
            <div className={styles["main"]}>
                {/* {searchParams.get('activeIdx') && navData[searchParams.get('activeIdx')].element()} */}
                <div className={styles["top"]}>
                <div className={styles["btn"]} onClick={(e)=>activeIdx==0?setActiveIdx(3):activeIdx==3?setActiveIdx(1):setActiveIdx(0)}>{activeIdx==0?navData[0].title:activeIdx==3?navData[3].title:navData[1].title}</div>
                </div>
                {activeIdx+1 && <>{navData[activeIdx].element}</>}

            </div>
        </div>
    </div>
}
export default Dashboard;