import { useEffect, useState } from "react";
import axios from "axios";
import Box from "./Box";
import styles from "./Boxes.module.css";

const Boxes = () => {
    const [allData, setAllData] = useState([]);
    const [loading, setLoading] = useState(true);

    const handleServices = async () => {
        try {
            const authToken = localStorage.getItem("jwt");
            const response = await axios.get(
                `https://api.healinroots.com/api/service`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            console.log("Services Fetched successfully", response.data.data);
            setAllData(response.data.data);
        } catch (error) {
            console.log("Error fetching services", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleServices();
    }, []);

    if (loading) return <div>Loading...</div>;

    return (
        <div className={styles["container"]}>
            <div className={styles["content"]}>
                {allData.map((el) => (
                    <div key={el._id} className={styles["box"]}>
                        <Box image={el.image} text={el.text} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Boxes;
