import React, { useEffect, useState } from "react";
import styles from "./Register.module.css";
import { Link, useNavigate } from "react-router-dom";
import { AddUser } from "../../../../utils/global.apis";
import { getToken, setItem } from "../Test/AsyncStorage";
import endUrls from "../Test/ApiUrl";
import axios from "axios";
import Header from "../../../sections/Header";
import Footer from "../../../sections/Footer";
import { useDisclosure } from "@nextui-org/react";
import MemberModal from "./MemberModal";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";

const Register = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("male");
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [id, setID] = useState("");
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = {
      name: name,
      dateOfBirth: dob,
      email: email,
      phone: phoneNumber,
      gender: gender,
    };

    try {
      if (name === "") {
        return;
      }

      const res = await AddUser(dataToSend);
      setItem("user_id", res.data.data._id);
      setItem("token", res.data.data.token);
      setItem("gender", gender);

      if (res.data.message === "User Added") {
        setIsLanguageModalOpen(true);
        setID(res.data.data._id);
        setToken(res.data.data.token);
      } else if (res.data.message === "User Logged In") {
        onOpen();
      } else {
        console.log("Unexpected message:", res.data.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleLanguageSelection = (language) => {
    setSelectedLanguage(language === "English" ? "I-E" : "I-H");
    setIsLanguageModalOpen(false);
  };
  useEffect(() => {
    if (selectedLanguage) {
      navigateToForm(id, token, gender);
    }
  }, [selectedLanguage]);

  const navigateToForm = (userId, token, gender) => {
    axios
      .get(`${endUrls.baseUrl}${endUrls.viewAttempt}/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((item) => {
        if (item.data.data.isAnswerCompleted) {
          alert("Answers are already filled");
        } else {
          setItem("user_id", userId);
          setItem("type", selectedLanguage);
          setItem("gender", gender);
          navigate("/form");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          setItem("user_id", userId);
          setItem("type", selectedLanguage);
          setItem("gender", gender);
          navigate("/form");
        } else {
          alert("Answer already exists for this user!");
        }
      });
  };

  return (
    <>
      <Header />
      <MemberModal
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
      />
      <Modal
        isOpen={isLanguageModalOpen}
        onOpenChange={() => setIsLanguageModalOpen(!isLanguageModalOpen)}
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1">
            Select Language
          </ModalHeader>
          <ModalBody style={{ height: "200px" }}>
            <p
              className={styles["member-line"]}
              onClick={() => handleLanguageSelection("English")}
            >
              English
            </p>
            <p
              className={styles["member-line"]}
              onClick={() => handleLanguageSelection("Hindi")}
            >
              Hindi
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              variant="light"
              onPress={() => setIsLanguageModalOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <div className="container">
        <div
          className="row justify-content-center align-items-center"
          style={{ paddingTop: "120px" }}
        >
          <div className="col-md-6 col-sm-12">
            <h2 className="text-center text-secondary">
              Fill Registration Form!
            </h2>
            <form className="p-5" onSubmit={(e) => handleSubmit(e)}>
              <div className="mb-4">
                <label className="form-label d-block ms-1">Gender</label>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="genderOptions"
                    id="maleGender"
                    value="male"
                    checked={gender === "male"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="maleGender">
                    Male
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="genderOptions"
                    id="femaleGender"
                    value="female"
                    checked={gender === "female"}
                    onChange={(e) => setGender(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="femaleGender">
                    Female
                  </label>
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label ms-1"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="exampleFormControlInput2"
                  className="form-label ms-1"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput2"
                  placeholder="abc@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="exampleFormControlInput3"
                  className="form-label ms-1"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="exampleFormControlInput3"
                  placeholder="1234567890"
                  value={phoneNumber}
                  onChange={(e) => {
                    // Improved validation with immediate feedback
                    let newValue = e.target.value.replace(/[^0-9]/g, ""); // Remove non-digits
                    if (newValue.length > 10) {
                      newValue = newValue.slice(0, 10); // Truncate to 10 characters
                    }
                    if (newValue.startsWith("0")) {
                      newValue = newValue.substring(1); // Remove leading zero
                    }
                    setPhoneNumber(newValue);
                  }}
                  pattern="[0-9]{10}"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="exampleFormControlInput4"
                  className="form-label ms-1"
                >
                  DOB
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="exampleFormControlInput4"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  required
                />
              </div>
              <div className="w-100 text-center">
                <button className="btn btn-secondary w-auto" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Register;
